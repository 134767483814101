.app {
  padding: 0px;
}

.main, .footer {
  padding: 20px;
}

.main ul {
  list-style: square;
  margin: 0;
  padding: 0 0 0 20px;
}

.content-footer {
  padding-top: 0px;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 10px;
}

.mainheader-title {
  font-size: 1.4em;
  text-align: center;
  background-color: #efefef;
  margin: 0;
  padding: 20px;
}

.page-title {
  font-size: 1.2em;
  border-bottom: 1px dotted #ddd;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.list-outside {
  list-style-position: outside;
  padding-left: 20px;
  margin-bottom: 0px;
}
